
/*
 *  This file configuration.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { customValidations } from '../validators/validations.enum';
import { environment } from 'src/environments/environment';
import Blitz from 'blitzllama-js';

@Injectable()
export class ConfigurationService {

    envName = '';
    domainCode = '';
    base_url = '';
    base_auth_url = '';
    file_size_in_mb = 5;
    stripe_base_url = '';
    kommunicationAppId = '';
    power_bi_url = "";
    microservice_base_url = '';
    broadcastUrl = '';
    hropsDeploymentEnable = false;
    hashDomain: any;
    loginDomain: any;
    configData: typeof environment | null = null;
    isGDPRPointerEnabled = false;
    apiDocumentUrl='';
    constructor(
        public _httpClient: HttpClient,
    ) { }

    loadConfig(): any {

        const data = environment;
        this.configData = data;
        this.isGDPRPointerEnabled = data.isGDPRPointerEnabled;
        this.hropsDeploymentEnable = data.hropsDeploymentEnable;
        this.domainCode = this.getDomainHttp(data);
        this.envName = data.envName;
        this.base_url = data.base_url;
        this.base_auth_url = data.base_auth_url;
        this.stripe_base_url = data.stripe_base_url;
        this.power_bi_url = data.power_bi_url;
        this.microservice_base_url = data.microservice_base_url;
        this.broadcastUrl = data.broadcastUrl;
        const api_key = Blitzllama_Api_key[data.domainCode];
        if (!data.isDeployOnGT && api_key) {
            Blitz.init(api_key);
        }
        this.apiDocumentUrl = data.apiDocumentUrl;


    }

    getDomainHttp(env) {
        if (env['envName'] === 'production') {
            const hd = this.getDomainFromUrlHash();
            localStorage.setItem('hashDomain', hd);
            this.hashDomain = hd;
            this.loginDomain = localStorage.getItem('loginDomain');

            if (this.isIP(location.origin)) {
                const paths: string[] = location.pathname.split('/').splice(1, 1);
                const basePath: string = (paths && paths[0]) || '';
                return basePath;
            } else {
                return location.hostname.split('.')[0];
            }

        } else {
            localStorage.setItem('hashDomain', '');
            return env['domainCode'];
        }
    }

    isIP(address) {
        const r =
            RegExp([
                '^https?:\/\/([a-z0-9\\.\\-_%]+:([a-z0-9\\.\\-_%])+?@)?',
                '((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4',
                '][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?',
                '(:[0-9]+)?(\/[^\\s]*)?$'
            ].join(''), 'i');
        return r.test(address);
    }

    getDomainFromUrlHash() {
        let d = '';
        const domain = window.location.hash.substring(1);
        const hashD = localStorage.getItem('hashDomain');
        const regex = new RegExp(customValidations.AlphaNumericOnly);
        if (domain && domain != 'hrone' && regex.test(domain)) {
            d = domain;
        } else if (domain && domain == 'hrone') {
            d = '';
        } else if (hashD) {
            d = hashD;
        }
        return d;
    }


}

export const Blitzllama_Api_key = {
    dgn5: 'key_BiqUWg68NM6Ykzr',
    'hronenew-testing': 'key_BiqUWg68NM6Ykzr',
    sanity: 'key_BiqUWg68NM6Ykzr',
    app: 'key_BevGHiYqgdzqMWT',
    demo: 'key_BXuNzt3RIBUeRba'
}
